// Global constant file
export const Constants = Object.freeze({
  url: {
    getVideoCount: "videoDetails/getVideoCount",
    getStageDetails: "videoDetails/getStageDetails",
    getShowDetails: "videoDetails/getShowDetails",
    checkSlugExistence: "create/checkUniqueIdentity",
    uploadVideo: "create",
    getArtistListing: "artist/getArtistListing",
    sentArtistToReview: "artist/sendToReview",
    sentCollectionToReview: "collection/sendToReview",
    sentShowToReview: "show/sendToReview",
    getArtistTabCount: "artist/getArtistCount",
    getUnbindArtistVideoList: "artist/getArtistVideo",
    getGenreSubGenreList: "meta/getGenreSubGenre",
    updateArtistDetail: "artist/saveArtist",
    viewArtistDetail: "artist/getArtist",
    getCollectionTabCount: "collection/getCollectionCount",
    getCollectionListing: "collection/getCollectionListing",
    viewCollectionDetail: "collection/getCollection",
    getCategoryList: "meta/getCategories",
    getShowTabCount: "show/showCount",
    getShowListing: "show/shows",
    sentIndividualToReview: "episode/sendToReview",
    getIndividualTabCount: "episode/episodeCount",
    getIndividualListing: "episode/episode",
    viewIndividualDetail: "episode/EpisodeDetailBySlug",
    getVideoList: "collection/getVideosList",
    getShowVideoList: "show/getShowVideos",
    getIndividualVideoList: "episode/getVideosList",
    getIndividualTrailerList: "episode/getIndividualTrailerList",
    uploadImage: "common/imageOperation",
    checkSlug: "common/checkSlug",
    updateCollectionDetail: "collection/saveCollection",
    updateIndividualDetail: "episode/saveIndividualEpisode",
    viewShowDetail: "show/getShowDetail",
    updateShowDetail: "show/saveShow",
    saveVideoDetails: "videoDetails/saveVideoDetails",
    getAllVideoDetail: "videoDetails/getAllVideoDetail",
    getIndividualArtistListing: "common/getArtists",
    removeVideo: "common/removeVideo",
    getReviewAndPublishAndActiveCount:
      "common/getReviewAndPublishAndActiveCount",
    getReviewActiveListing: "common/getReviewAndActiveData",
    getPublishListing: "common/getPublishData",
    moveToStage: "common/moveToStage",
    sendToCorrection: "common/changeStatus",
    changeComingSoonStatus: "common/changeComingSoonStatus",
    publishNow: "common/publish",
    homeArtist: "dashboard/artist",
    homeCommon: "dashboard/getData",
    watchTime: "dashboard/getUserAvgWatchTime",
    getUserIdDetails: "dashboard/getUserIdDetails",
    getVideoInfo: "dashboard/getAllVideoInfo",
    getLoginNonLoginUsers: "dashboard/getUserLoginDetails",
    getMostWatchVideos: "dashboard/getMostWatchedVideos",
    getNoVideoWatchUsers: "dashboard/getNoVideoWatchUsers",
    getUserLapsedTimeDateWise: "dashboard/getUserLapseTimeDateWise",
    getUserDateWiseWatchHistory: "dashboard/getUserDateWiseWatchHistory",
    getUserJourney: "dashboard/getUserJourney",
    getTrendingSearchData: "dashboard/getTrendingSearchData",
    getNotificationTotalCount: "notificationDashboard/getTotalCount",
    getNotificationContentList: "notificationDashboard/getContentList",
    getNotificationuserJourney: "notificationDashboard/userJourney",
    userWatchHistoryByDate: "notificationDashboard/userWatchHistoryByDate",
    genreDistribution: "dashboard/genreDistribution",
    getAllFormatDataCount: "dashboard/getAllFormatDataCount",
    getUserConsumptionCounts: "dashboard/getUserConsumptionCounts",
    getPlatterData: "other/getPlatterData",
    savePlatterData: "other/savePlatterData",
    getMostWatchedVideosTypeWise: "dashboard/getMostWatchedVideosTypeWise",
    getDataAnalysis: "dashboard/getDataAnalysis",
    deleteVideo: "videoDetails/deleteVideo",
    makeAudioVideoClip: "videoDetails/makeAudioVideoClip",
    getAllContentNotifcationData:
      "notificationDashboard/getAllContentNotifcationData",
    AddTemplate: "notificationDashboard/AddTemplate",
    scheduledNotifications: "notificationDashboard/scheduledNotifications",
    getlogs: "notificationDashboard/getlogs",
    saveComingSoon: "show/saveComingSoon",
    getComingSoonShowDetail: "show/getComingSoonShowDetail",
    saveComingSoonCollection: "collection/saveComingSoonCollection",
    getComingSoonCollection: "collection/getComingSoonCollection",
    saveComingSoonIndividualEpisode: "episode/saveComingSoonIndividualEpisode",
    ComingSoonEpisodeDetailBySlug: "episode/ComingSoonEpisodeDetailBySlug",
    newPublishNow: "common/newPublishNow",
    makeVideoClip: "videoDetails/makeVideoClip",
    saveVideoClipName: "videoDetails/saveVideoClipName",
    googleDrive: "videoDetails/googleDrive",
    getPlatterDataDODN: "other/getPlatterDataDODN",
    savePlatterDataDODN: "other/savePlatterDataDODN",
    getActiveContent: "common/getActiveContent",
    getStoryData: "other/getStoryData",
    getStoryContent: "common/getStoryContent",
    saveStoryDataDODN: "other/saveStoryDataDODN",
    uploadSubtitle: "videoDetails/uploadSubtitle",
    convertSrtToVtt: "videoDetails/convertSrtToVtt",
    getWAUData: "dashboard/getWAUData",
    getPheripheralContents: "common/getPheripheralContents",
    saveRecommendation: "common/saveRecommendation",
    deleteRecommendation: "common/deleteRecommendation",
    getRecommendationData: "common/getRecommendationData",
    getDriveClipProgress: "videoDetails/getDriveClipProgress",
    getActiveContentAllTab: "common/getActiveContentAllTab",
    getAllTabPlatterData: "other/getAllTabPlatterData",
    saveAllTabPlatterData: "other/saveAllTabPlatterData",
    getAllTabStoryData: "other/getAllTabStoryData",
    saveAllTabStory: "other/saveAllTabStory",
    getMonthlyVideoData: "dashboard/getMonthlyVideoData",
    getPlatterDataCombine: "other/getPlatterDataCombine",
    saveCombinePlatterData: "other/saveCombinePlatterData",
    getTipSett: "tiping/getTipSett",
    saveTippingData: "tiping/saveTippingData",
    getAppVersions: "dashboard/getAppVersions",
    getAllPlans: "dashboard/getAllPlans",
    givePlanToNumber: "dashboard/givePlanToNumber",
    getPlans: "subscription/getPlans",
    changePlanStatus: "subscription/changeStatus",
    removeSubscription: "subscription/removeSubscription",
    updatePlan: "subscription/updatePlan",
    userSubscriptionOffer: "subscription/createUsersOffer",
    getFailedTransactionsDetails: "subscription/getFailedTransactionsDetails",
    getUserSubscription: "subscription/getUserSubscription",
    getTeleServiceAssignee: "dashboard/getAllTeleUsersAssingee",
    getAllTeleServiceDashboardData: "dashboard/getAllTeleServiceDashboardData",
    saveTeleSalesAction: "subscription/saveTeleSalesAction",
    checkSubscriptionStatus: "subscription/checkSubscriptionStatus",
    systemSubscriptionCount: "dashboard/systemSubscriptionCount",
    csvDownload: "dashboard/csvDownload",
    getyoutubeData: "other/getyoutubeData",
    updateyoutubeData: "other/updateyoutubeData",
    getComplianceCategories: "meta/getComplianceCategories",
    assestList: "asset/list",
    assestDetail: "asset/detail",
    createEditAssest: "asset/createEditAssest",
    checkAssestDetail: "asset/checkSlug",
    getDefaultJson: "asset/defaultJson",
    submitAsset: "asset/submitAsset",
    showList: "show/showList",
    episodeList: "episode/episodeList",
    homeWidgetContent: "common/homeWidgetContent",
    updateHomeWidgetContent: "common/updateHomeWidgetContent",
    getInvoice: "common/getInvoices",
    getInvoiceDetail: "common/getInvoiceDetail",
    generatePreviewURL: "common/generatePreviewURL",    
    createPreviewLink: "common/createPreviewLink",
  },
  image: {
    hls: "/HLS/",

    artistSmall: "/44/",
    artistMedium: "/60/",
    artistLarge100: "/100/",
    artistLarge200: "/200/",

    verticalSmall: "/vertical/small/",
    verticalMedium: "/vertical/medium/",
    verticalLarge: "/vertical/large/",
    verticalOriginal: "/vertical/",

    horizontalSmall: "/horizontal/small/",
    horizontalMedium: "/horizontal/medium/",
    horizontalLarge: "/horizontal/large/",

    squareSmall: "/square/small/",
    squareMedium: "/square/medium/",
    squareLarge: "/square/large/",
    square: "/square/",
  },
  appBackend: {
    url: {
      remindMeNotification: "cron/remindMeNotify",
    },
  },
});
